@font-face {
  font-family: 'GOST type B';
  src: url('./gost_b.ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
strong, b {
  font-weight: 500;
}
body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.95rem;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
@media print {
  @page {
    size: A4 portrait;
  }
  body {
    color: black;
    background-color: #fff;
    margin-left: 18mm;
    margin-right: 8mm;
    margin-top: 8mm;
    margin-bottom: 12mm;
    font-family: Arial, Helvetica, sans-serif;
  }
  tr.break  { break-before: always; }
  tr.no-break  { break-before: avoid; break-inside: avoid; }
}

.splash {
  width: 512px;
  height: 128px;
  position: absolute;
  left: 48%;
  margin-left: -255px;
  margin-top: 140px;
}
.description {
  padding-left: 120px;
  font-size: 78%;
  line-height: 120%;
  color: #555;
}
.description p, .description ul, .description h1 {
  margin: 0;
}
.description ul {
  padding-left: 16px;
}
.description h1 {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-weight: 500;
}
@media (max-width: 640px) {
  .splash {
    width: 256px;
    height: 64px;
    margin-left: -128px;
    margin-top: 80px;
    background-size: cover;
  }
  .description {
    padding-left: 50px;
  }
}
